<template>
<div class="row mt-2 mb-4" style="background-color: #fff; border-radius: 20px; padding: 20px;">
	<!-- Header de classe-mini -->
	<div v-if="formato === 'categoria-mini'" class="row categoria-header mt-4">
		<div class="col categoria-text"> 
			<h1 style="margin-bottom: 0px !important;">{{titulo}}</h1>
		</div>
		<div class="col categoria-button">
			<router-link class="" to="/categorias">Veja Todos os Livros ></router-link>
		</div>
	</div>

	<!-- Header de classe -->
	<div v-else-if="formato === 'categoria'" class="row categoria-header mt-4 mb-2">
		<div class="col-1">
			<img class="categoria-img" :src="'https://www.perlego.com/images/subject_icons/' + iconeCategoria">
		</div>
		<div class="col categoria-text"> 
			<h1 style="margin-bottom: 0px !important;">{{titulo}}</h1>
		</div>
		<div class="col categoria-button">
			<router-link class="btn btn-primary" to="/categorias">Veja Todos os Livros</router-link>
		</div>
	</div>

	<!-- Header centralizado -->
	<div v-else>
		<h1>{{titulo}}</h1>
		<p>{{sub_titulo}}</p>
	</div>

	<!-- Swiper -->
	<Splide :options="options" :key="key_atualizacao">
		<Splide-slide v-for="livro in livros_categoria" v-bind:key="livro.id" class="livro">
			<a :href="livro.link_acesso">
				<img :src="livro.link_capa">
			</a>
		</Splide-slide>
	</Splide>
</div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import firebase from "firebase/app";
import 'firebase/firestore';


export default {
	name: "slidejs",
	components: {
		Splide,
		SplideSlide,
	},
	props: {
		titulo: String,
		sub_titulo: String,
		categoria: String,
		formato: String,
		iconeCategoria: String
	},
	mounted(){
		this.retornaTOPLivrosFirebasePorCurso(this.categoria, 20)
        // let dataRaw = [];
        // firebase.firestore().collection("/sandbox/sandbox-livros-1/" + this.categoria)
        // .limit(15)
        // .get()
        // .then((querySnapshot) => {
        //     querySnapshot.forEach((doc) => {
        //         // doc.data() is never undefined for query doc snapshots
        //         // console.log(doc.id, " => ", doc.data().titulo);
        //         dataRaw.push(doc.data())// Nossa variável
        //     });
		// 	this.livros_categoria = dataRaw
		// 	this.key_atualizacao++
        // })
        // .catch((error) => {
        //     console.log("Error getting documents: ", error);
		// });

    },
	data() {
		return {
			livros_categoria: [],
			key_atualizacao: 0,
			options: {
				type: 'loop',
				pagination: false,
				perPage: 8,
				perMove: 1,
				gap: '1rem',
				breakpoints: {
					0: {
						perPage: 2,
					},
					350: {
						perPage: 3,
					},
					400: {
						perPage: 4,
					},
					576: {
						perPage: 4,
					},
					768: {
						perPage: 5,
					},
					992: {
						perPage: 6,
					},
					1200: {
						perPage: 6,
					}
				}
			}
		}
	},
	methods: {
        retornaTOPLivrosFirebasePorCurso: function(curso, limite){
            console.log(curso, typeof curso, limite, typeof limite)
            let respostaRaw = [];
            firebase.firestore().collection("acervo-completo").where("top_lidos", "array-contains", curso)
            .orderBy("index")
            .limit(parseInt(limite))
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    respostaRaw.push(doc.data())
                });
                this.livros_categoria = respostaRaw
                // Atualiza o elemento. Essa key garante que o elemento inteiro seja atualizado, de forma que ele reinicie
                this.key_atualizacao++
            })
            .catch((error) => {
                console.log("Não foi possivel retornar os resultados solicitados para o curso: ", curso, ". Erro: ", error);
            });
        },
	}	
};

</script>

<style scoped>
.livro {
	transition: all .3s ease-in-out;
	padding: 10px 0;
}	
.livro:hover {
	transition: all .3s ease-in-out;
	-ms-transform: scale(1.4);
	-webkit-transform: scale(1.4);  
	transform: scale(1.1);
}
.livro img {  
	border-radius: 7px;
	box-shadow: var(--shadow) 0px 3px 8px;
	width: -webkit-fill-available;
}
.livro img:hover {  
	box-shadow: 0px 1px 5px 2px var(--shadow);
}
.categoria-header {
    align-items: center;
    padding-right: 0px !important;
}
.categoria-img{
    /* border-radius: 50%; */
    height: 3rem;
    width: 3rem;
    -o-object-fit: cover;
    /* object-fit: cover; */
    /* box-shadow: 0 1px 4px 0 rgb(40 29 50 / 20%); */
}
.categoria-text{
    text-align: left;
}
.categoria-button{
    text-align: right;
    padding-right: 0px !important;
	
}
.btn-primary{
	color: #6c757d;
	background: none;
	border-radius: 10px;
    border: 2px #6c757d solid;
}
.btn-primary:hover {
	color: #fff;
	background: #6c757d;
	border: 2px #6c757d solid;
}
@media screen and (max-width: 500px){
    .categoria-text > h1{
        text-align: left;
        font-size: 1.25rem;
    }
    .categoria-img{
        border-radius: 50%;
        height: 1.5rem;
        width: 1.5rem;
        -o-object-fit: cover;
        object-fit: cover;
        box-shadow: 0 1px 4px 0 rgb(40 29 50 / 20%);
    }
    .btn{
        font-size: 0.6rem;
    }
}


/* BOOTSTRAP TESTE */
/* Extra Small */
@media screen and (min-width: 0px){
	.test{
		border: #32b336 7px solid !important;
	}
	.livro img{
		height: 120px;
	}
}
		/* Small */
@media screen and (min-width: 576px){
	.test{
		border: #ffa600 7px solid !important;
	}
	.livro img{
		height: 150px;
	}
}

/* Medium*/
@media screen and (min-width: 768px){
	.test{
		border: #41a7ff 7px solid !important;
	}
	.livro img{
		height: 170px;
	}
}

/* Large*/
@media screen and (min-width: 992px){
	.test{
		border: yellow 7px solid !important;
	}
	.livro img{
		height: 200px;
	}
}

/* Extra large */
@media screen and (min-width: 1200px){
	.test{
		border: #ff0053 7px solid !important;
	}
	.livro img{
		max-height: 200px;
	}
}
</style>
